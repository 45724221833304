import revive_payload_client_qs8Atu2ATGNlBDr_bbdLbHBNiUz_roUr_czn9oBEgz8 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JOCfRbjbTQjqFgm47qL3fIKnoDbdy1w4U2aHsOy_Mvw from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5A8CWr3coBFiqG34rXN2yAEI7jCJOD3JP2nVfZ09efY from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_CBR_tuEBBEyWFa_Zf0EvpOAxVSY6s3s_6YzrFlXPmII from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_8vESL8_2C8AciZQeoIpqiHM2mQPdvhO3pneQiwxL5js from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client__Ug0217_dxFAEMbl7gJRiuWCTgJAscDaOaJXM1NzTMg from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9buEEPiHAEnpfn8HrTEsrELOVRtbv_7wWKILIGxD1wc from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9Id2tXxjOL6F8h9kaBl_H0BsgwhJyAceW2Q_URCNI18 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/uroweb/uroweb-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_ZtwLSAxVSwldVKgWbXqXuPEJAfW1DnMkxy3aw_ax4MQ from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__evw3mertmmjfcutduiuil6ccmu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_SLQVr_dVCeGL2lEzryIg5nt8painjf_B6ehYndPSIW4 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_YRPYi1PK_fvbGBTzPaOCDjgr84wsv7XBFNQ8qwxxi2w from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.2.0_graphql@16.10.0_magicast@0.3.5_nuxt@3.16.1_@parcel+watch_jlzuz7km6vuhexwik64ox6spfu/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.js";
import plugin_gq5Ozi3x2FNe8_c6XXDfszyRpAemu2EmaiJFXdk_xew from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI from "/builds/uroweb/uroweb-frontend/plugins/globals.ts";
import gtm_w1mUd627MTflyHOzfKw9s7kvhuSkBafhA0pnEHlbGcI from "/builds/uroweb/uroweb-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_qs8Atu2ATGNlBDr_bbdLbHBNiUz_roUr_czn9oBEgz8,
  unhead_JOCfRbjbTQjqFgm47qL3fIKnoDbdy1w4U2aHsOy_Mvw,
  router_5A8CWr3coBFiqG34rXN2yAEI7jCJOD3JP2nVfZ09efY,
  _0_siteConfig_CBR_tuEBBEyWFa_Zf0EvpOAxVSY6s3s_6YzrFlXPmII,
  payload_client_8vESL8_2C8AciZQeoIpqiHM2mQPdvhO3pneQiwxL5js,
  navigation_repaint_client__Ug0217_dxFAEMbl7gJRiuWCTgJAscDaOaJXM1NzTMg,
  check_outdated_build_client_9buEEPiHAEnpfn8HrTEsrELOVRtbv_7wWKILIGxD1wc,
  chunk_reload_client_9Id2tXxjOL6F8h9kaBl_H0BsgwhJyAceW2Q_URCNI18,
  plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_ZtwLSAxVSwldVKgWbXqXuPEJAfW1DnMkxy3aw_ax4MQ,
  plugin_SLQVr_dVCeGL2lEzryIg5nt8painjf_B6ehYndPSIW4,
  plugin_YRPYi1PK_fvbGBTzPaOCDjgr84wsv7XBFNQ8qwxxi2w,
  plugin_gq5Ozi3x2FNe8_c6XXDfszyRpAemu2EmaiJFXdk_xew,
  nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0,
  globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI,
  gtm_w1mUd627MTflyHOzfKw9s7kvhuSkBafhA0pnEHlbGcI
]